export const Reading = {
  user_id: 0,
  date: new Date().toJSON().slice(0, 10),
  sugarB: 0,
  carbsB: 0,
  insulinB: 0,
  insulinSB: 0,
  insulinFB: 0,
  chkMedsB: false,
  sugarL: 0,
  carbsL: 0,
  insulinL: 0,
  chkMedsL: false,
  sugarD: 0,
  carbsD: 0,
  insulinD: 0,
  chkMedsD: false,
  sugarBB: 0,
  carbsBB: 0,
  insulinBB: 0,
  sugarBed: 0,
  carbsBed: 0,
  insulinBed: 0,
  insulinSBed: 0,
  insulinFBed: 0,
  chkMedsBed: false,
};
