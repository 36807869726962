export const User = {
  id: 0,
  firstName: '',
  lastName: '',
  userName: '',
  password: '',
  email: '',
  isLogedIn: false,
  firstNameError: '',
  lastNameError: '',
  userNameError: '',
  passwordError: '',
  emailError: '',
  loginError: ''
};
