import styled from 'styled-components';

export const NavBarLink = styled.li`
  float: center;
  display: inline;
  color: #fff;
  justify-content: space-between;
  padding: 17px;
  text-decoration: none;
  font-size: 17px;
`;
