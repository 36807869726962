import React from 'react';
import './App.css';
import NavBar from './Components/NavBar';

export default function App() {
  return (
    <div className='App'>
      <NavBar />
    </div>
  );
}
