export const Bloodpressure = {
  user_id: 0,
  date: new Date().toJSON().slice(0, 10),
  hbp: 0,
  lbp: 0,
  hr: 0,
  hbp2: 0,
  lbp2: 0,
  hr2: 0,
};
